import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import clsx from 'clsx';
import {SEARCH_PARAMS} from "../constants/searchParamNames";
import "../scss/hamburger-menu.scss";

const HamburgerMenu: React.FC = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const isMenuOpen = searchParams.get(SEARCH_PARAMS.MENU);

  const handleClick = () => {
    if (isMenuOpen) {
      searchParams.delete(SEARCH_PARAMS.MENU)
    } else {
      searchParams.set(SEARCH_PARAMS.MENU, 'true');
    }
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.querySelector('body')?.classList.add('noScroll');
    } else {
      document.querySelector('body')?.classList.remove('noScroll');
    }
  },[isMenuOpen])

  return (
    <div className="ms-2 ms-3 cursor-pointer" onClick={handleClick}>
      <div className={clsx( 'yettel-menu', isMenuOpen && 'open')} >
        <div className={clsx( 'yettel-menu__icon', isMenuOpen && 'open')}></div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
