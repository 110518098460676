import {useQuery} from '@tanstack/react-query';
import {useEffect, useMemo} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {SpecialOffersResult} from '../types/types';
import {callPublicAPI} from '../utils/api';
import LoadingIndicator from "./LoadingIndicator";
import FullWidthPageContent from "./FullWidthPageContent";
import OfferList from "./OfferList";
import PageTitle from "./PageTitle";
import Button from "./Button";
import SpecialOfferDisplay from "./SpecialOfferDisplay";
import SpecialPrizeAlreadySaved from "./SpecialPrizeAlreadySaved";
import {QUERY_KEYS} from '../constants/queryKeys';
import {SEARCH_PARAMS} from "../constants/searchParamNames";
import {LOCAL_STORAGE_KEYS} from "../constants/localStorageKeys";
import {PAYMENT_TYPES} from "../constants/paymentType";
import Discount from "../assets/ysh-discount.webp";
import {isBusinessUser} from "../utils/user.ts";
import '../scss/special-prize.scss';

export default function SpecialPrizePage() {
  const [searchParams] = useSearchParams();
  const isHidden = searchParams.get(SEARCH_PARAMS.HIDE_HEADER_FOOTER) ?? undefined;
  const isPostpaid = localStorage.getItem(LOCAL_STORAGE_KEYS.PAYMENT_TYPE) === PAYMENT_TYPES.POSTPAID
  const isBusiness = isBusinessUser();
  const navigate = useNavigate();

  const { isLoading, isError, data } = useQuery<SpecialOffersResult>({
    queryKey: [
      QUERY_KEYS.GET_SPECIAL_OFFERS,
    ],
    queryFn: async () => {
      const response = await callPublicAPI<SpecialOffersResult>("GET", "/offers/special")
      return response.data
    },
    enabled: isPostpaid
  });

  const isInvoicePaid = useMemo(() => Object.prototype.hasOwnProperty.call(Object(data?.offers[0]), 'id'), [data?.offers])

  useEffect(() => {
    if (!isPostpaid || isBusiness) {
      navigate('/');
    }
  },[isPostpaid, navigate, isBusiness])


  if (data?.codeAssigned) {
    return <SpecialPrizeAlreadySaved />
  }
  if (isLoading) {
    return <LoadingIndicator />
  }
  if (isError) {
    navigate('/error')
  }

  return (
    <div className="special-prize-page">
      <Helmet>
        <title>Popust iznenađenja | Yettel Shopping</title>
      </Helmet>
      <FullWidthPageContent>
        <div className="height-holder-title">
          <PageTitle className="mb-2">
            <section className="flex justify-between items-center">
              <div>Popust iznenađenja</div>
            </section>
          </PageTitle>
          <p className="mb-4">
            Spremili smo sjajne kupone za popuste! Potrebno je samo da, putem aplikacije, platiš račun do 20og i svakog meseca možeš da iskoristiš jedan od ponuđenih kupona.<br /> Sačuvan kupon ćeš pronaći u sekciji Račun/Popusti iznenađenja.
          </p>
        </div>

        <OfferList className="position-relative height-holder-list">
          {data?.offers && Boolean(data?.offers.length) && data?.offers.map((offer) => (
            <div
              key={offer.id}
              className={'special-offer'}
            >
              {!isInvoicePaid && (
                <>
                  <div className="special-offer__hidden">
                    <div className="special-offer__hidden__discount">
                      <img src={Discount} alt="discount" width={60} height={60} />
                    </div>
                  </div>
                  <SpecialOfferDisplay
                    offer={offer}
                    imgLoadingType="lazy"
                  />
                </>
              )}
              {isInvoicePaid && <SpecialOfferDisplay offer={offer} showButton={true} /> }
            </div>
          ))}
        </OfferList>

        {(!isInvoicePaid && !isHidden) && (
          <a href="https://ytl.rs/ya">
            <Button secondary className="mt-4 p-4">Idi na Yettel aplikaciju</Button>
          </a>
        )}
      </FullWidthPageContent>
    </div>
  )
}
