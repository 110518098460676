import Girl from '../assets/ysh-girl.webp';
import "../scss/special-prize-already-saved.scss";
import {Link} from "react-router-dom";
import Button from "./Button.tsx";

const SpecialPrizeAlreadySaved = () => {
  return (
    <div className="special-offer-already-saved">
      <p>
        Popust iznenadjenja je već otvoren i sačuvan u sekciji Kuponi.
      </p>
      <p>
        Svakog meseca plati račun na vreme putem aplikacije i pogledaj novi izbor kupona sa popustima.
      </p>
      <div className="special-offer-already-saved__image-holder">
        <img src={Girl} alt="girl" />
      </div>

      <div className="mt-5">
        <Link to='/kuponi'>
          <Button>Pogledaj preuzete kupone</Button>
        </Link>
      </div>
    </div>
  )
}

export default SpecialPrizeAlreadySaved;
