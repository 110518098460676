import React from 'react';
import '../scss/button.scss';

interface Props {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  secondary?: boolean;
  disabled?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, onClick, className, secondary, disabled = false }, ref) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        className={`button ${secondary ? 'button--secondary' : ''} d-block fz-18 ps-4 pe-4 pt-2 pb-2 font-yettel ${className} ${disabled ? 'button--disabled' : ''}`}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
);

export default Button;

