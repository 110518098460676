import {useEffect} from "react";
import {Link, useSearchParams} from "react-router-dom";
import clsx from 'clsx';
import { PiCaretRightBold } from "react-icons/pi";
import {isUserLoggedIn} from "../utils/auth";
import {SEARCH_PARAMS} from "../constants/searchParamNames";
import {LOCAL_STORAGE_KEYS} from "../constants/localStorageKeys";
import "../scss/hamburger-menu.scss";
import {PAYMENT_TYPES} from "../constants/paymentType";
import {isBusinessUser} from "../utils/user.ts";

type MenuItem = {
    title: string;
    path: string;
    isProtected?: boolean;
}

const menuLinks: MenuItem[] = [
  {
    title: 'Naslovna',
    path: '/'
  },
  {
    title: 'Ponude',
    path: '/ponude'
  },
  {
    title: 'Kategorije',
    path: '/ponude'
  },
  {
    title: 'Moji kuponi',
    path: '/kuponi'
  },
  {
    title: 'Yettel Petak',
    path: '/yettel-petak'
  },
  {
    title: 'Popust iznenađenja',
    path: '/special',
    isProtected: true
  },
  {
    title: 'Podešavanja i sugestije',
    path: '/profil'
  }
]

const HamburgerMenuPopup: React.FC = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const isLoggedIn = isUserLoggedIn();
  const isPostpaid = localStorage.getItem(LOCAL_STORAGE_KEYS.PAYMENT_TYPE) === PAYMENT_TYPES.POSTPAID;
  const isBusiness = isBusinessUser();
  const isMenuOpen = searchParams.get(SEARCH_PARAMS.MENU);

  const handleClick = () => {
    if (isMenuOpen) {
      searchParams.delete(SEARCH_PARAMS.MENU)
    } else {
      searchParams.set(SEARCH_PARAMS.MENU, 'true');
    }
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.querySelector('body')?.classList.add('noScroll');
    } else {
      document.querySelector('body')?.classList.remove('noScroll');
    }
  },[isMenuOpen])

  return (

    <div className={clsx( 'yettel-menu-popup', isMenuOpen && 'open')}>
      <div className="border" />
      <div className="yettel-menu-popup__link_container">
        {menuLinks.map(menuItem => {
          if (menuItem?.isProtected && (!isLoggedIn || !isPostpaid || isBusiness)) {
            return
          }
          return (
            <Link key={menuItem.title} to={menuItem.path} className="yettel-menu-popup__link_container__link font-yettel" onClick={handleClick}>
              <span>{menuItem.title}</span><span className="font-yettel-bold fz-14 pt-1 arrow"><PiCaretRightBold/> </span>
            </Link>)
        })}
      </div>

    </div>

  );
};

export default HamburgerMenuPopup;
