import { differenceInDays, differenceInSeconds, endOfDay, intervalToDuration } from 'date-fns';
import { ExpirationType, Offer, SingleOffer } from '../types/types';

export function isValidForLessThanOneMinute(validityEnd: Date | null, isExpired?: boolean | null) {
  if (validityEnd && !isExpired) {
    return differenceInSeconds(validityEnd, new Date()) < 59;
  }
  return false;
}

export function isValidForLessThanOneDay(validityEnd: Date | null) {
  if (validityEnd) {
    return differenceInDays(validityEnd, new Date()) < 1;
  }
  return false;
}

export function isValidForFiveOrLessDays(validityEnd: Date | null) {
  if (validityEnd) {
    return differenceInDays(validityEnd, new Date()) < 6;
  }
  return false;
}

export function durationEndsWithTwoThreeFourButDoesntBeginWithOne(num: number) {
  return (
    (num.toString().endsWith('2') || num.toString().endsWith('3') || num.toString().endsWith('4')) &&
    num !== 12 &&
    num !== 13 &&
    num !== 14
  );
}

export function durationEndsWithButDoesntBeginWithOne(num: number): boolean {
  return num.toString().endsWith('1') && num !== 11;
}

export function getExpiration(validityEnd: Date | null) {
  if (validityEnd) {
    const durationInMonths = intervalToDuration({
      start: new Date(),
      end: validityEnd
    }).months;
    const durationInDays = intervalToDuration({
      start: new Date(),
      end: validityEnd
    }).days;

    const durationInMonthsString = durationInMonths
      ? `${durationInMonths} ${
        durationEndsWithButDoesntBeginWithOne(durationInMonths)
          ? 'mesec '
          : durationEndsWithTwoThreeFourButDoesntBeginWithOne(durationInMonths)
            ? 'meseca '
            : 'meseci '
      }`
      : '';

    const durationInDaysString = durationInDays
      ? `${durationInMonths ? ' i ' : ''}${durationInDays} ${
        durationEndsWithButDoesntBeginWithOne(durationInDays) ? 'dan' : 'dana'
      }`
      : '';

    if (durationInMonths || durationInDays) {
      return `${durationInMonthsString}${durationInDaysString} `;
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export const isCouponTaken = (offer: SingleOffer | Offer) => {
  return offer.with_code !== 'no-code' && offer.code !== undefined;
};

export const validityEnd = (offer: SingleOffer | Offer, expirationType: ExpirationType): Date | null => {
  if (expirationType === 'coupon') {
    return endOfDay(new Date((offer as SingleOffer).date_code_end));
  }

  if (expirationType === 'offer') {
    return endOfDay(new Date(offer.date_end));
  }

  return null;
};

export const isExpired = (offer: SingleOffer | Offer, expirationType: ExpirationType) => {
  if (validityEnd(offer, expirationType)) {
    return differenceInSeconds(validityEnd(offer, expirationType)!, new Date()) < 1;
  }
  return false;
};

export function getMinutesString(num: number) {
  if (durationEndsWithButDoesntBeginWithOne(num)) {
    return ' minut';
  } else {
    return ' minuta';
  }
}

export function getSecondsString(num: number) {
  if (durationEndsWithButDoesntBeginWithOne(num)) {
    return `${num} sekundu`;
  } else if (durationEndsWithTwoThreeFourButDoesntBeginWithOne(num)) {
    return `${num} sekunde`;
  } else if (num === 0) {
    return '';
  } else {
    return `${num} sekundi`;
  }
}

export function getHoursString(num: number) {
  if (durationEndsWithButDoesntBeginWithOne(num)) {
    return ' sat';
  } else if (durationEndsWithTwoThreeFourButDoesntBeginWithOne(num)) {
    return ' sata';
  } else if (num === 0) {
    return '';
  } else {
    return ' sati';
  }
}
