import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { IoSearch } from "react-icons/io5";

const SearchBarTrigger: React.FC = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const handleClick = () => {
    searchParams.set(SEARCH_PARAMS.SEARCH, 'true');
    searchParams.delete(SEARCH_PARAMS.MENU);
    setSearchParams(searchParams);
  }

  return (
    <IoSearch onClick={handleClick} className="yettel-search cursor-pointer"/>
  );
}

export default SearchBarTrigger;
