import { useMatch } from 'react-router-dom';
import clsx from 'clsx';
import { IconContext } from 'react-icons';
import { MdArrowBack } from 'react-icons/md';
import SearchBarTrigger from './SearchBarTrigger';
import LocationListContainer from './LocationListContainer';
import HamburgerMenu from "./HamburgerMenu";

interface Props {
  navigateToHomepage: Function;
}

const HeaderMobile: React.FC<Props> = ({ navigateToHomepage }) => {
  const isHomePage = useMatch('/');
  const isPonudePage = useMatch('/ponude');

  return (
    <header
      className={clsx(
        'yettel-header-mobile bg-primary-color',
        !isHomePage ? 'short-header' : '',
        !isPonudePage ? 'mb-4' : ''
      )}
    >
      {!isPonudePage ? (
        <div className="full-width d-flex justify-content-between align-items-center ps-4 pe-2">
          <div className="cursor-pointer" onClick={() => navigateToHomepage()}>
            <h1 className="color-secondary-color font-yettel-bold fz-25 mt-1">Y. Shopping</h1>
          </div>
          <div className="d-flex ms-2 me-2">
            <SearchBarTrigger />
            <HamburgerMenu />
          </div>
        </div>
      ) : (
        <div className="full-width d-flex justify-content-between align-items-center ps-2 pe-2">
          <div className="cursor-pointer" onClick={() => navigateToHomepage()}>
            <div className="d-flex">
              <IconContext.Provider value={{ color: '#A2FF00', size: '20px' }}>
                <MdArrowBack />
              </IconContext.Provider>
              <span className="color-secondary-color font-yettel-bold fz-18">Naslovna</span>
            </div>
          </div>
          <div className="d-flex ms-2 me-2">
            <SearchBarTrigger />
            <HamburgerMenu />
          </div>
        </div>
      )}

      {isHomePage && (
        <h2 style={{ color: 'white' }} className="mt-4 mb-5 pt-1 fz-18 ps-2">
          Svi dobri popusti na jednom mestu!
        </h2>
      )}
      {isHomePage && <LocationListContainer />}
    </header>
  );
};

export default HeaderMobile;
