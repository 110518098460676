import { ScratchOffer, ScratchOfferResponse, ApiErrorResponse, isApiErrorResponse } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { Link } from 'react-router-dom';
import YettelPetakScratchTrigger from './YettelPetakScratchTrigger';
import { isUserLoggedIn } from '../utils/auth';
import Button from './Button';
import smile from '../assets/images/icons/icon-smile.png';
import arrowRight from '../assets/images/arrows/icon-arrow-r.svg';
import ErrorPageContent from './ErrorPageContent';
import { ErrorCodes } from '../constants/FridayScratch';
import '../scss/yettel-petak.scss';

const YettelPetakPage = () => {
  const isLoggedIn = isUserLoggedIn();
  const defaultErrorMessage = `Žao nam je, ali bez registracije pripejd broja nije moguće da ogrebeš iznenađenje koje smo ti pripremili u Yettel petku!
 
Da ogrebeš iznenađenje, registruj svoj pripejd broj i prilikom registracije potvrdi saglasnost.`;
  const {
    isLoading,
    isError,
    isSuccess,
    data: scratchOffer
  } = useQuery<ScratchOfferResponse, ApiErrorResponse>({
    queryKey: [QUERY_KEYS.GET_SCRATCH_OFFERS],
    retry: false,
    queryFn: async () => {
      const response = await callPublicAPI<ScratchOffer>('GET', '/scratch-offers');
      return response;
    },
    enabled: isLoggedIn
  });

  return (
    <>
      {!isLoading && isError && <GeneralError />}

      {!isLoading &&
        isApiErrorResponse(scratchOffer) &&
        scratchOffer.code.endsWith(ErrorCodes.UNREGISTERED_POSTPAID) && (
        <UnregisteredPrepaidNotAllowed message={defaultErrorMessage} />
      )}

      {!isLoading && isApiErrorResponse(scratchOffer) && scratchOffer.error.description === 'forbidden_tp' && (
        <TpNotAllowedMessage />
      )}

      {!isLoading &&
        isApiErrorResponse(scratchOffer) &&
        scratchOffer.error.description !== 'forbidden_tp' &&
        !scratchOffer.code.endsWith(ErrorCodes.UNREGISTERED_POSTPAID) && (
        <TracedErrorMessage trace={scratchOffer.metadata.trace} />
      )}

      {!isLoading && isSuccess && scratchOffer.data && !isApiErrorResponse(scratchOffer.data) && (
        <>
          <YettelPetakScratchTrigger scratchOffer={scratchOffer.data} />

          <Link to={'/pregled-dobitaka'} className="yettel-petak-item">
            <div className="icon-wrapper">
              <img className="icon" src={smile} alt="pregled dobitaka" />
            </div>
            <div className="content-wrapper">
              <p className="text">Pregled dobitaka</p>
              <img className="arrow" src={arrowRight} alt="arrow right" />
            </div>
          </Link>
        </>
      )}
    </>
  );
};

interface UnregisteredPrepaidNotAllowedProps {
  message: string;
}

const UnregisteredPrepaidNotAllowed: React.FC<UnregisteredPrepaidNotAllowedProps> = ({ message }) => {
  return (
    <div className="yettel-petak-wrapper">
      <p style={{ whiteSpace: 'pre-line' }}>{message}</p>
      <div className="yettel-petak-button mt-4">
        <Link to="https://www.yettel.rs/sr/privatni/ponuda/tarifni-paketi/pripejd/registracija/">
          <Button>Registruj svoj prepaid broj</Button>
        </Link>
      </div>
    </div>
  );
};

const TpNotAllowedMessage = () => {
  return (
    <div className="yettel-petak-wrapper">
      <p>
        Žao nam je :( ali vaša pretplata{' '}
        {localStorage.getItem(LOCAL_STORAGE_KEYS.TP_NAME) !== 'null'
          ? `na paket ${localStorage.getItem(LOCAL_STORAGE_KEYS.TP_NAME)}`
          : ''}{' '}
        nema opciju Yettel petak grebalice. Pristup grebalici imaju svi korisnici Total, Go data kao i prepaid paketa.
        Ukoliko želite, u svakom trenutku možete preko vaše Yettel aplikacije promeniti željeni paket.
      </p>
      <div className="yettel-petak-button mt-4">
        <Link to="https://www.yettel.rs/sr/privatni/paketi-i-dodaci/mobilna/">
          <Button>Pogledaj pakete</Button>
        </Link>
      </div>
    </div>
  );
};

const TracedErrorMessage = ({ trace }: { trace: string }) => {
  return (
    <>
      <ErrorPageContent />
      <div className="text-center fz-18">
        Kod kojim možemo pratiti tvoju grešku je: <br />
        <span className="font-yettel-bold fz-19">{trace}</span>.<br />
        Prosledi sliku ovog ekrana našem timu i potrudićemo se da rešimo problem.
      </div>
    </>
  );
};

const GeneralError = () => {
  return (
    <>
      <ErrorPageContent />
      <div className="text-center fz-18">Pokušaj ponovo kasnije.</div>
    </>
  );
};

export default YettelPetakPage;
