import {YettelPetakHistoryActivation} from "../types/types";
import arrowRight from "../assets/images/arrows/icon-arrow-r.svg";
import {useState} from "react";
import PopupOverlay from "./PopupOverlay";
import PopupContainer from "./PopupContainer";
import {getPrizeValue, isStringPrizeType} from "../constants/prizes";
import {VOUCHER_STATUSES, VoucherStatus} from "../constants/voucherStatuses";
import {PROMOTION_STATUSES} from "../constants/promotionStatuses";
import {formatDate} from "../utils/util";
import {Link, useSearchParams} from "react-router-dom";
import Button from "./Button";
import StoreSelector from "./StoreSelector.tsx";
import {PROVISIONED, USED} from "../constants/scratchCard";
import {SEARCH_PARAMS} from "../constants/searchParamNames.ts";
import Checked from "../assets/images/icons/icon-checked.png"
import "../scss/yettel-petak-history-item-modal.scss";

interface Props {
    item: YettelPetakHistoryActivation;
}

const YettelPetakHistoryItem: React.FC<Props> = ({item}) => {
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isSelectStoreSubmitted = searchParams.get(SEARCH_PARAMS.SHOW_STORE_JUST_SAVED_MODAL) === 'true';

  async function copyTextToClipboard(textToCopy: string) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log("copied to clipboard");
    } catch (error) {
      console.log("failed to copy to clipboard. error=" + error);
    }
  }

  const startDate = new Date(item.promotionDate);
  const endDate = new Date(item.voucherDetails?.validTo as string);
  const isVoucherType = item.promotionType === "VOUCHER";
  const voucherStatus =
        isVoucherType && item.voucherDetails
          ? item.voucherDetails.status.toUpperCase()
          : PROMOTION_STATUSES.PENDING;
  const statusText = isVoucherType
    ? VOUCHER_STATUSES[voucherStatus as VoucherStatus]
    : PROMOTION_STATUSES[item.promotionProvisioningStatus.toUpperCase()];

  const value = isStringPrizeType(item.renderType)
    ? getPrizeValue(item.renderType, item.promotionCategory, item.value)
    : item.value;

  return (
    <>
      <div onClick={() => setIsPopupActive(true)} className="profile-page-item">
        <div className="content-wrapper">
          <p className="text">{value}</p>
          <img className="arrow" src={arrowRight} alt="arrow right"/>
        </div>
      </div>
      {isPopupActive && (
        <PopupOverlay onClick={() => setIsPopupActive(false)}>
          <PopupContainer
            isActive={isPopupActive}
            setIsActive={setIsPopupActive}
            handlePopupClosure={() => setIsPopupActive(false)}
            contentClassName={"yettel-petak-history-item-modal"}
          >
            {isSelectStoreSubmitted ? (
              <>
                <div className="text-center">
                  <img src={Checked} alt="success" width={50} height={50}/>
                  <p className="mt-4">
                     Telefon će biti spreman za preuzimanje za 7 dana u izabranoj prodavnici.
                  </p>
                  <Button className="mt-5 mb-5" onClick={() => {
                    setIsPopupActive(false)
                    searchParams.set(SEARCH_PARAMS.SHOW_STORE_JUST_SAVED_MODAL, '')
                    setSearchParams(searchParams);
                  }}>OK</Button>
                </div>
              </>
            ) : (<>
              <h2 className="mb-4 text-center">{value}</h2>
              <section className="font-yettel-light mb-5">
                <p className="mt-3 mb-3 text-center">
                                        Status:{" "}
                  <span
                    className={
                      (!isVoucherType &&
                                                    item.promotionProvisioningStatus === PROVISIONED) ||
                                                (isVoucherType && voucherStatus === USED.toLowerCase())
                        ? "status-active"
                        : "status-inactive"
                    }
                  >
                    {statusText}
                  </span>
                </p>
                <p className="mt-3 mb-3 text-center">
                                        Datum dobitka: {formatDate(startDate)}
                </p>
                {isVoucherType && (
                  <p className=" text-center">
                                            Datum isteka: {formatDate(endDate)}
                  </p>
                )}
                <p className="mt-3 mb-3">{item.promotionStatusMessage}</p>
                {item.renderType === "linkAndButton" && (
                  <>
                    <p
                      className="mt-3 mb-3 copy-link text-center"
                      onClick={() =>
                        copyTextToClipboard(item.metadata?.code as string)
                      }
                    >
                                                Kopiraj kod
                    </p>
                    <br/>
                  </>
                )}
                {(item.renderType === "link" ||
                                        item.renderType === "linkAndButton") && (
                  <Link to={item.metadata?.link as string}>
                    <Button>Poseti sajt</Button>
                  </Link>
                )}
                {item.voucherDetails?.barcode && (
                  <img
                    className="arrow"
                    src={`data:image/png;base64,${item.voucherDetails?.barcode}`}
                    alt="barcode"
                  />
                )}
                {(item.userMetadata?.store) && (
                  <>
                    <p className="mt-2">
                    Telefon će biti dostupan za 7 dana od datuma biranja prodavnice.
                    </p>
                    <p className="mt-2 mb-3"> U momentu kada telefon stigne u prodavnicu kontaktiraćemo te, nakon čega je
                                            rok za preuzimanje 30 dana.</p>
                    <p className="bg-grey selected_store__address font-yettel">
                                            Odabrana prodavnica <br/>
                      {item.userMetadata.store.address} <br/> {item.userMetadata.store.city}
                    </p>
                    <p className="color-secondary-text mt-3 text-center ps-5 pe-5">
                                            Izmena adrese dostave moguća samo zvanjem call centra
                    </p>
                  </>
                )}
                {(item.storeNeeded === 1 && !item.userMetadata?.store) &&
                  <StoreSelector activationId={item.id}/>}
              </section>
            </>
            )}

          </PopupContainer>
        </PopupOverlay>
      )}
    </>
  );
};

export default YettelPetakHistoryItem;
