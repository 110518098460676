import React, { useState} from 'react';
import {DropdownOption} from "../types/types.ts";
import "../scss/searchable-dropdown.scss";

interface SearchableDropdownProps {
    options: DropdownOption[];
    placeholder?: string;
    onSelect: (value: string) => void;
    value: string;
    handleChange: (value: string) => void;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({ value, handleChange, options, placeholder, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = (value: string) => {
    handleChange(options.find(option => option.value === value)?.label || '');
    setIsOpen(false);
    onSelect(value);
  };

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(value.toLowerCase())
  );

  return (
    <div className="dropdown">
      <input
        className="dropdown-input"
        type="text"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        onClick={() => setIsOpen(!isOpen)}
        placeholder={placeholder}
      />
      {isOpen && (
        <div className="dropdown-menu">
          {filteredOptions.map(option => (
            <div
              key={option.value}
              className="dropdown-item"
              onClick={() => handleOptionSelect(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
