import React, {useCallback, useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import Dropdown from './SearchableDropdown';
import Button from "./Button.tsx";
import {
  DropdownOption,
  SaveSelectedStoreData,
  YettelPetakActivationsHistory,
  YettelStore
} from "../types/types.ts";
import {isUserLoggedIn} from "../utils/auth.ts";
import {QUERY_KEYS} from "../constants/queryKeys.ts";
import {callPublicAPI} from "../utils/api.ts";
import {useSearchParams} from "react-router-dom";
import {SEARCH_PARAMS} from "../constants/searchParamNames.ts";
import '../scss/store-selector.scss';

type Props = {
    activationId: string;
}
const StoreSelector: React.FC<Props> = ({activationId}: Props) => {
  const isLoggedIn = isUserLoggedIn();
  const [searchParams, setSearchParams] = useSearchParams();

  const {data} = useQuery<YettelPetakActivationsHistory>({
    queryKey: [QUERY_KEYS.GET_YETTEL_PETAK_HISTORY],
    queryFn: async () => {
      const response = await callPublicAPI<YettelPetakActivationsHistory>("GET", "/scratch-offers/history");
      return response.data;
    },
    enabled: isLoggedIn,
  });

  const [citySearch, setCitySearch] = useState<string>('');
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [storeSearch, setStoreSearch] = useState<string>('');
  const [selectedStore, setSelectedStore] = useState<string | null>(null);

  // Mutation for "Save and Send"
  const saveSelectedStore = useMutation({
    mutationFn: async (refuseGift?: boolean) => {
      const data: SaveSelectedStoreData = {id: activationId};
      if (selectedStore) {
        data.shopId = +selectedStore;
      }
      if (refuseGift) {
        data.refuseGift = 1;
        delete data.shopId;
      }
      return await callPublicAPI<undefined>("PATCH", "/scratch-offers/store", null, data);
    },
    onSuccess: (r) => {
      if (r.statusCode === 200) {
        searchParams.set(SEARCH_PARAMS.SHOW_STORE_JUST_SAVED_MODAL, 'true');
        setSearchParams(searchParams);
      }
    },
    onError: () => {

    }
  });

  const handleSelectCity = (city: string) => {
    setSelectedCity(city);
    setStoreSearch('');
    setSelectedStore(null);
  }

  const getCityOptions = useCallback(() => {
    return [...new Set(data?.stores?.map((store: YettelStore) => store.city))].map((city) => ({
      value: city,
      label: city,
    }));
  }, [data?.stores]);

  const getStoreOptions = useCallback(() => {
    if (!selectedCity) {
      return []
    }
    const relevantStores = data?.stores.filter((store) => store.city === selectedCity);
    if (!relevantStores?.length) {
      return []
    }
    return relevantStores.map((store) => {
      return ({value: String(store.id), label: store.address} as DropdownOption)
    })
  }, [data?.stores, selectedCity]);


  return (
    <div className="store-selector">
      <div className="field">
        <label>Izaberi grad / opštinu</label>
        <Dropdown
          options={getCityOptions()}
          placeholder="Grad"
          value={citySearch}
          handleChange={setCitySearch}
          onSelect={(value) => {
            handleSelectCity(value)
          }}
        />
      </div>
      <div className="field">
        <label>Izaberi prodavnicu</label>
        <Dropdown
          options={getStoreOptions()}
          value={storeSearch}
          handleChange={setStoreSearch}
          placeholder="Adresa prodavnice"
          onSelect={(value) => setSelectedStore(value)}
        />
      </div>
      <Button
        onClick={() => saveSelectedStore.mutate(false)}
        disabled={!selectedStore || !selectedCity}
      >
                Sačuvaj i pošalji
      </Button>
      <div
        className="no-gift-button text-center"
        onClick={() => saveSelectedStore.mutate(true)}
      >
        <p> Ne želim poklon</p>
      </div>
      <p className="color-secondary-text text-center">
          Kasnija izmena adrese dostave moguća samo zvanjem call centra.</p>
      {saveSelectedStore.isError && (
        <div className="error">Došlo je do greške prilikom slanja. Molimo Vas pokušajte ponovo kasnije.</div>
      )}
    </div>
  );
};

export default StoreSelector;
