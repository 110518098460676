import { Offer } from "../types/types";
import { IMAGE_BASE_URL } from "../constants/config";
import CouponsUsedCount from "./CouponsUsedCount";
import OfferAction from "./OfferAction.tsx";
import {isUserLoggedIn} from "../utils/auth.ts";
import "../scss/offer.scss";

interface Props {
    offer: Offer;
    showButton?: boolean;
    isUserLoggedIn?: boolean;
    linkTo?: string;
    imgLoadingType?: "lazy" | "eager";
    imgFetchPriority?: "high" | "low";
}

const SpecialOfferDisplay: React.FC<Props> = ({ offer, imgLoadingType, imgFetchPriority, showButton = false }) => {
  return (
    <div className="offer pb-2">
      <img
        className="offer__image"
        alt={`${offer.partner_name} - ${offer.name}`}
        src={`${IMAGE_BASE_URL}/${offer.image0}`}
        width={207}
        height={103}
        loading={imgLoadingType}
        fetch-priority={imgFetchPriority}
      />
      <section className="ps-2 pe-2 pb-2">
        <h3 className="offer__partner fz-16 mt-4 mb-2 font-yettel-bold">{offer.partner_name}</h3>
        <h4 className="offer__name mb-3 font-yettel-light">{offer.name}</h4>
        {showButton &&
          <OfferAction offer={offer} isUserLoggedIn={Boolean(isUserLoggedIn)} displayedIn={'special'}/>
        }
      </section>
      <section className="d-flex offer__info">
        {Boolean(offer.used_code_count) && <CouponsUsedCount usedCount={offer.used_code_count} />}
      </section>
    </div>
  )
}

export default SpecialOfferDisplay;
