import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from "react-router-dom";
import router from './router';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './config/queryClientFactory';
import CookieBot from 'react-cookiebot';

const domainGroupId = '67696235-e0b5-419e-9788-b37934d81c9e';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
    {import.meta.env.PROD === true && <CookieBot domainGroupId={domainGroupId}/>}
  </React.StrictMode>
)
