import { NavLink } from 'react-router-dom';
import {isUserLoggedIn} from "../utils/auth.ts";
import {LOCAL_STORAGE_KEYS} from "../constants/localStorageKeys.ts";
import {PAYMENT_TYPES} from "../constants/paymentType.ts";
import {isBusinessUser} from "../utils/user.ts";

const DesktopNavigation = () => {
  const navItems = [
    { path: '/', label: 'Pregled' },
    { path: '/near-by', label: 'Mapa' },
    { path: '/kuponi', label: 'Moji kuponi' },
    { path: '/yettel-petak', label: 'Y. Petak' },
    {
      label: 'Popust iznenađenja',
      path: '/special',
      isProtected: true
    },
    { path: '/profil', label: 'Profil' }
  ];

  return (
    <nav>
      <ul className="yettel-header__navigation d-flex">
        {navItems.map(item => (
          <DesktopNavigationElement label={item.label} path={item.path} isProtected={item?.isProtected}/>
        ))}
      </ul>
    </nav>
  );
};

const DesktopNavigationElement: React.FC<{ label: string; path: string, isProtected?: boolean }> = ({ label, path, isProtected }) => {
  const isLoggedIn = isUserLoggedIn();
  const isPostpaid = localStorage.getItem(LOCAL_STORAGE_KEYS.PAYMENT_TYPE) === PAYMENT_TYPES.POSTPAID;
  const isBusiness = isBusinessUser();
  if (isProtected && (!isLoggedIn || !isPostpaid || isBusiness)) {
    return
  }
  return (
    <li>
      <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to={path}>
        {label}
      </NavLink>
    </li>
  );
};

export default DesktopNavigation;
