import { useEffect, useRef, useState } from 'react';
import '../scss/my-saved-coupons.scss';
import { NALOG } from '../constants/config';

interface Props {
  codeCount: number | null;
  countedAt: string;
}

const MySavedCouponsCount: React.FC<Props> = ({ codeCount, countedAt }) => {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const transformDate = (dateString: string) => {
    const dateObject = new Date(dateString);

    const prefixWithZero = (num: number) => {
      if (num > 9) return num.toString();

      return `0${num}`;
    };

    const month = prefixWithZero(dateObject.getMonth() + 1);
    const day = prefixWithZero(dateObject.getDate());

    const hours = prefixWithZero(dateObject.getHours());
    const minutes = prefixWithZero(dateObject.getMinutes());

    return `${day}.${month}. u ${hours}:${minutes}`;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setShowDisclaimer(false);
      }
    };

    if (popupRef && triggerRef) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="my-saved-coupons d-flex align-items-center">
      <div className="fz-16 font-yettel ms-3 me-2">
        {typeof codeCount === 'number' && (
          <>
            Sačuvani kuponi u nagradnoj igri ({codeCount}) <br />
            <div className="mt-2 fz-14 font-yettel-light">
              Poslednji put ažurirano {transformDate(countedAt)}
            </div>
          </>
        )}

        {codeCount === null && (
          <span>
            Za učešće u nagradnoj igri, poveži broj telefona na svoj{' '}
            <a className="text-decoration-underline" href={NALOG}>
              nalog
            </a>
            .
          </span>
        )}
      </div>
      <div ref={triggerRef} className="popup-trigger" onClick={() => setShowDisclaimer(!showDisclaimer)}></div>

      {showDisclaimer && (
        <div ref={popupRef} className="popup-content">
          <header>
            <div className="close" onClick={() => setShowDisclaimer(false)}></div>
          </header>
          <div className="popup-content__content fz-14 font-yettel-light">
            <p className="mb-2">
              Prikazani broj sačuvanih kupona odnosi se na ukupan broj kupona sačuvanih u ovom nagradnom takmičenju. Ukoliko za vreme
              trajanja nagradnog takmičenja promeniš podatke o broju telefona ili mejl adresi na svom Yettel nalogu,
              prikaz aktivnih i isteklih kupona može biti manji od ukupnog broja sačuvanih kupona prikazanih iznad.
            </p>
            <p>
              Bez obzira na to, svi tvoji sačuvani kuponi su zabeleženi u sistemu. Za više informacija, piši nam na{' '}
              <a className="text-decoration-underline" href="mailto:yettelpartner@yettel.rs">
                yettelpartner@yettel.rs
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </section>
  );
};

export default MySavedCouponsCount;
