import { ScratchOffer } from '../types/types';
import Sheet from 'react-modal-sheet';
import { useEffect, useState } from 'react';
import header from '../assets/yettelPetak/header-grebalica.png';
import defaultCountdownImg from '../assets/yettelPetak/Counter-Yettel-Petak1080x1230.png';
import { getCurrentYear } from '../utils/util';
import close from '../assets/images/icons/icon-close-green.png';
import '../scss/yettel-petak-countdown-modal.scss';

interface Props {
  scratchOffer: ScratchOffer;
  isModalActive: boolean;
  setIsModalActive: (active: boolean) => void;
}

const YettelPetakCountdown: React.FC<Props> = ({ isModalActive, setIsModalActive, scratchOffer }) => {
  const calculateTimeLeft = (): {
    hours: number;
    minutes: number;
    seconds: number;
  } => {
    const difference =
      (scratchOffer.hasOwnProperty('next_scratch_at') &&
        new Date(scratchOffer?.next_scratch_at as string).getTime() - new Date().getTime()) ||
      0;
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return { hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  const days = Math.floor(timeLeft.hours / 24);
  const remainingHours = timeLeft.hours % 24;

  const closePopup = () => {
    setIsModalActive(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new Date(scratchOffer?.next_scratch_at as string)]);

  return (
    <Sheet isOpen={isModalActive} onClose={closePopup} className="countdown-modal">
      <Sheet.Container>
        <Sheet.Header>
          <div className="d-flex justify-content-end pt-3 pb-2">
            <img className="cursor-pointer" width={25} height={25} src={close} alt="close modal" onClick={closePopup} />
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <div className="content-yettel-petak-countdown">
            <img className="header" alt="scratch" src={header} />
            <section className="content-yettel-petak-countdown__image-container">
              <img alt="scratch" src={scratchOffer?.image ? scratchOffer.image : defaultCountdownImg} />

              <div className="counter">
                {scratchOffer.hasOwnProperty('next_scratch_at') ? (
                  <>
                    <h2>Ogrebi i osvoji za:</h2>
                    <div className="counter-txt">
                      {Boolean(days) && <h2>{`${days} ${days === 1 ? 'dan' : 'dana'}`}</h2>}

                      <h2>{`${remainingHours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}</h2>
                    </div>
                  </>
                ) : (
                  <h2 className="text-center ps-3 pe-3">Nagrada za ovaj petak je već ogrebana.</h2>
                )}
              </div>
            </section>
            <div className="footer">
              <p className="ps-2 pe-2 pt-2 pb-3 font-yettel-light">
                Yettel petak je dostupan svakog petka u periodu od 01.01.{getCurrentYear()}. do 31.12.{getCurrentYear()}
                . izmedju 08:00 - 23:59h. Za vise detalja o uslovima promocije posetite{' '}
                <a className="text-decoration-underline" href="https://www.yettel.rs">
                  www.yettel.rs
                </a>
              </p>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closePopup} />
    </Sheet>
  );
};

export default YettelPetakCountdown;
